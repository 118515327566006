<template>
  <div class="page flex-col">
    <van-nav-bar
      title="支付结果"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="mainPart">
      <div class="payres">
        <img src="./assets/img/payIcon.png" alt="">
        <p>支付成功</p>
        <van-button plain hairline round type="primary" @click="handleOrderInfo">查看订单</van-button>
        <van-button class="backHome" round type="primary" @click="handleHome">返回首页</van-button>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    handleHome(){
      this.$router.push('/home')
    },
    handleOrderInfo(){
      this.$router.push('/userOrder')
    },
    onClickLeft(){
      this.$router.go(-1)
    }
  }
};
</script>
<!-- <style scoped lang="css" src="./assets/index.rem.css" /> -->

<style scoped lang="css" >
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-size: 37.5px;
  height: 100%;
}
::v-deep .van-nav-bar .van-icon{
  color: #333;
}
/* 按钮 颜色 */
::v-deep .van-button--plain.van-button--primary{
  color:#fb743a;
  border: 1px solid #fb743a;
}
::v-deep .van-button--primary{
  width: 13.3rem;
}
.backHome{
  margin-top: 1.2rem;
  background-color: #fb743a;
  color:#fff;
  border-color: #fb743a;
}
.mainPart{
  background-color: #f3f3f3;
}
.payres{
  margin-top: 1.12rem;
  padding: 4.53rem 0 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payres img{
  width: 9.3rem;
  height: 6.2rem;
}
.payres p{
  margin: 1.12rem;
  font-size: 1.33rem;
  line-height: 1.33rem;
  color: #333;
}
</style>